import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fluidFontSize,
  headingStyles,
  visuallyHidden,
} from '../styles';
import { Container, Overline, Button, Svg, AnimatedCircles } from './ui';
import scrollIconEn from '../images/scroll-icon-en.svg';
import scrollIconDe from '../images/scroll-icon-de.svg';
import scrollIconEs from '../images/scroll-icon-es.svg';

const StyledBanner = styled.section`
  position: relative;
  overflow: hidden;

  ${({ displayWave }) => {
    if (displayWave) {
      return css`
        padding-bottom: 80px;

        ${minBreakpointQuery.small`
          padding-bottom: 100px;
        `}

        ${minBreakpointQuery.large`
          padding-bottom: 120px;
        `}
      `;
    }
  }};
`;

const commonInnerStyles = hasImage => {
  if (hasImage) {
    return css`
      ${minBreakpointQuery.small`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      `}

      ${minBreakpointQuery.medium`
        gap: 60px;
      `}

      ${minBreakpointQuery.large`
        gap: 100px;
      `}
    `;
  }
};

const StyledInner = styled.div`
  position: relative;
  ${({ hasImage }) => commonInnerStyles(hasImage)};
`;

const StyledInnerContainer = styled(Container)`
  ${({ hasImage }) => commonInnerStyles(hasImage)};
  padding-top: 30px;
  padding-bottom: 30px;

  ${minBreakpointQuery.small`
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    padding-top: 60px;
    padding-bottom: 60px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-top: 80px;
    padding-bottom: 80px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 100px;
    padding-bottom: 100px;
  `}
`;

const StyledContent = styled.div`
  align-self: center;

  ${minBreakpointQuery.large`
    position: relative;
  `}

  ${({ isSubpageWithImage }) => {
    if (isSubpageWithImage) {
      return css`
        padding: 30px;

        ${minBreakpointQuery.small`
          padding-right: 0;
        `}

        ${minBreakpointQuery.large`
          margin-left: auto;
          padding-top: 120px;
          padding-bottom: 120px;
          width: 550px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-top: 140px;
          padding-bottom: 140px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-top: 160px;
          padding-bottom: 160px;
        `}
      `;
    }
  }};

  ${({ isSystemPage }) => {
    if (isSystemPage) {
      return css`
        text-align: center;
      `;
    }
  }};
`;

const StyledHiddenHeading = styled.h1`
  ${visuallyHidden()};
`;

const StyledH1Heading = styled.h1`
  ${headingStyles()};
  display: inline-block;
  max-width: 600px;
`;

const StyledH2Heading = styled.h2`
  ${headingStyles()};
`;

const StyledText = styled.p`
  margin-top: 18px;
  ${fluidFontSize(
    '18px',
    '25px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.large`
    margin-top: 22px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledScrollIcon = styled(Svg)`
  ${maxBreakpointQuery.large`
    display: none;
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80px;
    width: 80px;
  `}

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${minBreakpointQuery.large`
          bottom: -100px;
        `}
      `;
    }
  }};
`;

const StyledImageWrapper = styled.div`
  justify-self: center;
  width: 100%;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${maxBreakpointQuery.small`
          display: none;
        `}

        ${minBreakpointQuery.small`
          position: relative;
          display: flex;
        `}
      `;
    }
  }};
`;

const StyledImage = styled(Img)`
  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        margin: 30px auto;
        width: 100%;
        max-width: 220px;
      `;
    } else {
      return css`
        ${maxBreakpointQuery.small`
          max-height: 300px;
        `}

        ${minBreakpointQuery.small`
          height: 100%;
        `}
      `;
    }
  }};
`;

const StyledWave = styled.canvas`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Banner = ({
  overline,
  heading,
  text,
  link,
  image,
  locale,
  hiddenHeading,
  displayWave,
  waveColour,
  isHomepage,
  isSystemPage,
}) => {
  const isSubpageWithImage = !isHomepage && image;
  const InnerComponent = isSubpageWithImage
    ? StyledInner
    : StyledInnerContainer;

  const HeadingComponent = !hiddenHeading ? StyledH1Heading : StyledH2Heading;

  useEffect(() => {
    if (displayWave) {
      // essential variables
      var canvas = document.getElementById('wave'),
        ctx = canvas.getContext('2d'),
        aniId;
      // parameters
      var w = (canvas.width = window.innerWidth),
        h = (canvas.height = window.innerHeight),
        particles = [], //particle array
        level = 1,
        color = waveColour || brandColours.primary,
        c;
      // particle object constructor
      function particle(x, y, d) {
        this.x = x;
        this.y = y;
        this.d = d;
        this.respawn = function () {
          this.x = Math.random() * (w * 0.8) + 0.1 * w;
          this.y = Math.random() * 30 + h - ((h - 100) * level) / 100 - 50 + 50;
          this.d = Math.random() * 5 + 5;
        };
      }
      // function to start or restart the animation
      function init() {
        c = 0;
        particles = [];
        for (var i = 0; i < 40; i++) {
          var obj = new particle(0, 0, 0);
          obj.respawn();
          particles.push(obj);
        }
        aniId = window.requestAnimationFrame(draw);
      }
      // function that draws into the canvas in a loop
      function draw() {
        ctx.clearRect(0, 0, w, h);
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        // draw the liquid
        ctx.beginPath();
        ctx.moveTo(w, h - ((h - 100) * level) / 100 - 50);
        ctx.lineTo(w, h);
        ctx.lineTo(0, h);
        ctx.lineTo(0, h - ((h - 100) * level) / 100 - 50);
        var temp = 50 * Math.sin((c * 1) / 50);
        ctx.bezierCurveTo(
          w / 3,
          h - ((h - 100) * level) / 100 - 50 - temp,
          (2 * w) / 3,
          h - ((h - 100) * level) / 100 - 50 + temp,
          w,
          h - ((h - 100) * level) / 100 - 50
        );
        ctx.fill();
        update();
        aniId = window.requestAnimationFrame(draw);
      }
      // function that updates variables
      function update() {
        c++;
        if (100 * Math.PI <= c) c = 0;
        for (var i = 0; i < 40; i++) {
          particles[i].x = particles[i].x + Math.random() * 2 - 1;
          particles[i].y = particles[i].y - 1;
          particles[i].d = particles[i].d - 0.04;
          if (particles[i].d <= 0) particles[i].respawn();
        }
      }
      // update canvas size when resizing the window
      window.addEventListener('resize', function () {
        // update the size
        w = canvas.width = window.innerWidth;
        h = canvas.height = window.innerHeight;
        // stop the animation befor restarting it
        window.cancelAnimationFrame(aniId);
        init();
      });
      // start animation
      init();
    }
  }, [displayWave, waveColour]);

  return (
    <StyledBanner displayWave={displayWave}>
      {displayWave && <StyledWave id="wave" />}
      <InnerComponent hasImage={image}>
        <StyledContent
          isSubpageWithImage={isSubpageWithImage}
          isSystemPage={isSystemPage}
        >
          {hiddenHeading && (
            <StyledHiddenHeading>{hiddenHeading}</StyledHiddenHeading>
          )}
          {overline && <Overline>{overline}</Overline>}
          <HeadingComponent>{heading}</HeadingComponent>
          {text && <StyledText>{text}</StyledText>}
          {link && link.page && (
            <StyledButton to={link.page} arrow={true}>
              {link.text}
            </StyledButton>
          )}
          <StyledScrollIcon
            image={
              locale === 'de'
                ? scrollIconDe
                : locale === 'es'
                ? scrollIconEs
                : scrollIconEn
            }
            isHomepage={isHomepage}
          />
        </StyledContent>
        {image && (
          <StyledImageWrapper isHomepage={isHomepage}>
            {!isSubpageWithImage && <AnimatedCircles />}
            <StyledImage
              fluid={image.fluid}
              alt={image.alt}
              isHomepage={isHomepage}
            />
          </StyledImageWrapper>
        )}
      </InnerComponent>
    </StyledBanner>
  );
};

export default Banner;

export const BannerImageFragment = graphql`
  fragment HomepageBannerImageFragment on DatoCmsFileField {
    fluid(
      maxWidth: 360
      imgixParams: { auto: "compress", fit: "crop", w: "360" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }

  fragment SubpageBannerImageFragment on DatoCmsFileField {
    fluid(
      maxHeight: 600
      maxWidth: 890
      imgixParams: { auto: "compress", fit: "crop", h: "600", w: "890" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }
`;
